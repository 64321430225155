import React, { useEffect, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { TrustCenterState } from '../center/index.en'
import { CommonContentList } from 'components/Trust/Card/CommonCard'
import SectionContent from 'components/SectionContent'
import Layout from 'layouts/en'
import * as styles from './index.module.less'

const Compliance: React.FC = (props) => {
  const { trustCenterEnYaml } = useStaticQuery(
    graphql`
      query {
        trustCenterEnYaml {
          compliance {
            title
            description
            key
            satitle
          }
          banner_bg_pc
          banner_bg_mb
          compliance_banner_info {
            content
            title
          }
          compliance_tab_list {
            is_active
            link
            name
          }
          bottom_card_title
          bottom_card_desc
          compliance_content_info {
            desc
            title
          }
          compliance_content_list {
            title
            content {
              content
            }
            icon
          }
        }
      }
    `,
  )

  const { compliance, compliance_tab_list, compliance_banner_info, compliance_content_info, compliance_content_list } =
    trustCenterEnYaml

  return (
    <Layout {...props}>
      <TrustCenterState
        trustCenterEnYaml={trustCenterEnYaml}
        TDK={compliance}
        bannerInfo={compliance_banner_info}
        tabList={compliance_tab_list}
      >
        <>
          <SectionContent
            className={styles.trustCompliance}
            title={compliance_content_info.title}
            descText={compliance_content_info.desc}
            lang="en"
          >
            <CommonContentList data={compliance_content_list} smallIcon={compliance_content_info?.small_icon} />
          </SectionContent>
        </>
      </TrustCenterState>
    </Layout>
  )
}

export default Compliance
